module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-theme-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../gatsby-theme-minimal/gatsby-browser.js'),
      options: {"plugins":[],"poweredID":["4f4d4555b111aa3e8e00005d","bzn-WCXu3PXpS3ScTWuaMIZq_g","bzn-AOFLZXy6QDmt55zNX6AExw"],"businessID":["bzn-GIA76PAtQfqzjajYI29Awg","bzn-WCXu3PXpS3ScTWuaMIZq_g","bzn-AOFLZXy6QDmt55zNX6AExw"],"multiSite":{"version":"v1","variantName":"multiLocationsV1","options":{"containerStyles":"{\"variant\":\"customVariants.locationsPage\"}"}},"navigation":{"version":"v11","variantName":"navigationV11","additionalNavLinks":[],"options":{"numberDesktopNavLinks":[11,11,11],"hideNavLinks":[[""],[""],[""]],"phoneHidden":true}},"footer":{"version":"v6","variantName":"","additionalNavLinks":[],"options":{"containerStyles":"{\"variant\":\"customVariants.footer\"}"}},"ctaWidget":{"version":"v1","variantName":"ctaWidgetV1","options":{"containerStyles":"{\"variant\":\"customVariants.ctaWidget\"}"}},"contactForm":{"componentName":"ContactFormV1","variantName":"contactFormV1","options":{"title":"Contact Us","subtitle":"","contactDetails":false,"backgroundImages":["contactform","contactform","contactform"],"containerStyles":"{\"variant\":\"customVariants.contactForm\"}"}},"home":{"customPageName":"","pageComponents":[]},"about":{"customPageName":"","className":"about-siena","pageComponents":[],"order":12},"menu":{"customPageName":"","pageComponents":[],"order":2},"events":{"customPageName":"","pageComponents":[],"order":7},"gallery":{"customPageName":"","pageComponents":[],"order":8},"contact":{"customPageName":"","pageComponents":[],"order":9},"additionalPages":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
